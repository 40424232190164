import { captureException } from "@sentry/nextjs"

export function reportError(message: string, cause?: unknown) {
  console.error(message, cause)

  let combinedMessage = message
  let lastError = cause

  while (lastError instanceof Error && lastError.message) {
    combinedMessage += `: ${lastError.message}`
    lastError = lastError.cause
  }

  captureException(new Error(combinedMessage))
}
