"use client"

import Link, { LinkProps } from "next/link"
import { ComponentProps, ReactNode } from "react"
import { useCountryCode } from "src/localization/client"
import { DEFAULT_COUNTRY_CODE } from "src/localization/localization"

export type LocalizedClientLinkProps = ComponentProps<"a"> &
  LinkProps & {
    children?: ReactNode
    href: string
    onClick?: () => void
  }

/**
 * Use this component to create a Next.js `<Link />` that persists the current country code in the url,
 * without having to explicitly pass it as a prop.
 */
export const LocalizedClientLink = ({ children, href, ...props }: LocalizedClientLinkProps) => {
  const countryCode = useCountryCode()

  if (!href) {
    return <div className={props.className}>{children}</div>
  }

  const localizedHref = countryCode === DEFAULT_COUNTRY_CODE ? href : `/${countryCode}${href}`

  return (
    <Link {...props} href={localizedHref}>
      {children}
    </Link>
  )
}
