"use client"

import { ccm19Show } from "src/integrations/ccm19/api"
import { useLocalizedText } from "src/localization/client"
import { META_DATA } from "src/metadata/constants"

export const CopyrightAndCookies = () => {
  const T = useLocalizedText(text)

  return (
    <section
      aria-label="Cookies und Copyright"
      className="flex flex-col items-center lg:items-start gap-4 whitespace-nowrap text-sm"
    >
      <button type="button" onClick={ccm19Show} className="opacity-75 hover:opacity-100">
        {T.cookieSettings}
      </button>

      <div className="opacity-75">
        © {META_DATA.address.companyName}. {T.allRightsReserved}.
      </div>
    </section>
  )
}

const de = {
  cookieSettings: "Cookie-Einstellungen",
  allRightsReserved: "Alle Rechte vorbehalten",
}

const en: typeof de = {
  cookieSettings: "Cookie settings",
  allRightsReserved: "All rights reserved",
}

const text = { de, en }
