import(/* webpackMode: "eager" */ "/opt/render/project/src/assets/illustrations/FAM_Background.svg");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/assets/logos/FAM_LogoKlein_Color.svg");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/assets/logos/FAM_LogoKlein_White.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Integrations"] */ "/opt/render/project/src/src/integrations/Integrations.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/src/modules/common/components/LocalizedClientLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopyrightAndCookies"] */ "/opt/render/project/src/src/modules/layout/components/CopyrightAndCookies.tsx");
